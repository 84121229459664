import { useEffect } from "react";

function StoreMainPage() {
  useEffect(() => {
    window.location.href = "https://www.manyvids.com/Profile/1008747768/missharleyjxyde/Store/Items";
  }, []);

  return null; // Optional: Return a placeholder if needed
}

export default StoreMainPage;
